<div class="container">
</div>

<div class="row art_row">
    <div class="parent">
        <div class="box1 col-md-12 col-sm-12 col-lg-6">
            <h3 class="title1">{{'Art comes alive at' | translate}}</h3>
            <!-- <h1 class="title2">{{'MOMus' | translate}}</h1> -->
            <h6 class="paragraph1">{{"Do you want to see works of art come to life before your eyes? Then you are at the right place! Visit the MOMus-Museum of Contemporary Art and enjoy a 'journey' in art with the help of VR technology. Wander through the virtual reality hall and interact with famous artworks in a specially designed room full of surprises." | translate}}</h6>
        </div>
        <div class="box2 col-md-12 col-sm-12 col-lg-6">
            <img class="photovr" src="assets/images/vr-first-image_web.jpg">
        </div>
    </div>
</div>
<br>

<div class="row wait_row">
    <div class="parent">
        <div class="box4 col-md-12 col-sm-12 col-lg-6">
            <h1 class="title4">{{'What to' | translate}} {{'expect' | translate}}</h1>
            <h6 class="paragraph_big">{{"Works by Edgar Degas, Liubov Popova, Alexandr Rotchenko, Solomon Nikritin, Dennis Oppenheim, Yiannis Moralis, Alekos Fassianos, Natassa Papadopoulou, Vasso Katraki, Costas Coulentianos and Alex Mylona acquire movement and compose an extremely interesting navigation in space and time, with the human form as a basic axis. Starting from" | translate}} {{"Paris in the end of the 19th century, going through revolutionary Moscow and the era of the Russian avant-garde, visitors end up virtually in Greece to discover the flourishing of contemporary art. Animated avatars act as guides and fellow travelers on this 'adventure'!" | translate}}</h6>
        </div>
        <div class="box3 col-md-12 col-sm-12 col-lg-6">
            <img class="photovr2" src="assets/images/momusVRpage_τιναπεριμενετε.jpg">
        </div>
    </div>
</div>

<div class="row" style="background: #f3f3f3; margin-top: 80px; width: 100%; margin-right: 0px; margin-left: 0px;">
    <div class="col">
    </div>

    <!-- <div class="col-7" style="padding-left: 0px;">
        <h1 class="titlefaqs">{{'Frequently Answered Questions' | translate}}</h1>
        <br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• MOMus VR is suitable for ages 13+.' | translate}}</a><br><br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• To enjoy the virtual tour, we offer you a set of special glasses and a joystick to help you navigate.' | translate}}</a><br><br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• The tour takes about 10-15 minutes.' | translate}}</a><br><br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• If it is your first time to try VR environment browsing, our people are there to help you, and our virtual guide will walk you step by step through this experience.' | translate}}</a><br><br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• When using the equipment, all necessary precautions are taken. Our tools are getting cleaned immediately after each use to be used again in absolute safety.' | translate}}</a><br><br>
        <a style="color: #3e3f3a; text-decoration: none; text-transform: none; font-style: italic; font-size: 17px;">{{'• The VR experience is offered at MOMus-Museum of Contemporary Art with the entrance ticket, no additional charge.' | translate}}</a><br><br>       
    </div> -->
    
    <div class="col">
    </div>
</div>