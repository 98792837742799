<ng-container *ngIf="current_step==1; else stepTwo">
    <app-create-exhibition [exhibition_obj]="exhibition_obj"
     (exhibitionTitleCreated)=onTitleAdded($event)>
    </app-create-exhibition>
</ng-container>

<ng-template #stepTwo>
    <div *ngIf="current_step==2; else stepThree">
        <app-create-exhibition2 [exhibition_obj]="exhibition_obj"
            (goToPreviousStep)=onPreviousStepSelection($event)
            (exhibitionWorksCreated)=onWorksAdded($event)>
        </app-create-exhibition2>
    </div>
</ng-template>

<ng-template #stepThree>
    <div *ngIf="current_step==3; else stepFour">
        <app-create-exhibition3 [exhibition_obj]="exhibition_obj"
            (goToPreviousStep)=onPreviousStepSelection($event)
            (exhibitionWorksCreated)=onWorksAdded($event)>
        </app-create-exhibition3>
    </div>
</ng-template>

<ng-template #stepFour>
    <div *ngIf="current_step==4; else stepFive">
        <app-create-exhibition4 [exhibition_obj]="exhibition_obj"
            (goToPreviousStep)=onPreviousStepSelection($event)
            (exhibitionWorksCreated)=onWorksAdded($event)>
        </app-create-exhibition4>
    </div>
</ng-template>

<ng-template #stepFive>
    <div *ngIf="current_step==5; else stepSix">
    <app-create-exhibition5  [exhibition_obj]="exhibition_obj"
       (goToPreviousStep)=onPreviousStepSelection($event)
       (exhibitionWorksCreated)=onWorksAdded($event)>
    </app-create-exhibition5>
    </div>
</ng-template>

<ng-template #stepSix>
    <div *ngIf="current_step==6; else stepSix">
    <app-create-exhibition6  [exhibition_obj]="exhibition_obj"
       (goToPreviousStep)=onPreviousStepSelection($event)
       (exhibitionPositionCreated)=onPositionAdded($event)
       (exhibitionWorksCreated)=onWorksAdded($event)
    ></app-create-exhibition6>
    </div>
</ng-template>