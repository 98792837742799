<div class="container">
    <h1 class="title">{{'Welcome!' | translate}}</h1>
    <form #userForm="ngForm" (NgSubmit)="userForm.valid && onLoginSubmit(userForm)">
        <div class="row justify-content-center">
            <div class="mb-4">
                <label for="exampleInputEmail1" class="form-label"></label>
                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" class="form-control email-input" id="exampleInputEmail1"
                    aria-describedby="emailHelp" placeholder="{{ 'Email address' | translate }}" name="email" [(ngModel)]="artist.email"
                    #email="ngModel" email required>
                <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Enter your email address!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Must be a valid email address.' | translate}}</small>
            </div>
            <div class="mb-4">
                <label for="exampleInputPassword1" class="form-label"></label>
                <input type="password" class="form-control pass-input" id="exampleInputPassword1" placeholder="{{ 'Password' | translate }}"
                    name="password" [(ngModel)]="artist.password" #password="ngModel" minlength="6" required>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Password should be at least 6 characters long.' | translate}}</small>
            </div>
        </div>
        <div class="mb-4">
            <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-black" [disabled]="!userForm.form.valid"
                    (click)="onLoginSubmit(userForm)">{{'Log in' | translate}}</button>
            </div>
        </div>
    </form>
    <div class="row mb-4">
        <!-- <div class="col-sm">
            <a class="forget" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}" (click)="openModalForgot()">{{'Forgot password?' | translate}}</a>
        </div> -->
        <!-- <div class="col-sm">
            <a class="signup" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}" (click)="openModalSignup()">{{'Create account' | translate}}</a>
        </div> -->
    </div>
    <p class="paragraph-proteceted">{{'Protected by reCAPTCHA and subject to the Google Privacy' | translate}} <br> {{'Policy and Terms of Service' | translate}}</p>
</div>