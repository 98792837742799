<div>
  <button style="border: 1px solid;" (click)="openNewEntryForm()" [ngStyle]="{'font-weight': showNewEntryForm ? 'bold' : 'normal'}">Νέα Εγγραφή</button>
  <button style="border: 1px solid;" (click)="switchDataType('Items')" [ngStyle]="{'font-weight': dataType === 'Items' ? 'bold' : 'normal'}">{{ dataType === 'Items' ? 'Φωτογραφίες (Ανανέωση)' : 'Φωτογραφίες' }}</button>
  <button style="border: 1px solid;" (click)="switchDataType('Photographers')" [ngStyle]="{'font-weight': dataType === 'Photographers' ? 'bold' : 'normal'}">{{dataType ==='Photographers' ? 'Φωτογράφοι (Ανανέωση)' : 'Φωτογράφοι'}}</button>
</div>

<ag-grid-angular
  style="width: 100%; height: 500px;"
  class="ag-theme-alpine"
  [rowData]="rowData"
  [columnDefs]="dataType === 'Items' ? itemColDefs : photographerColDefs"
  [defaultColDef]="defaultColDef"
>
</ag-grid-angular>

<!-- New Entry Form
<div *ngIf="showNewEntryForm">
  <form (submit)="addNewEntry()">
    <ng-container *ngIf="dataType === 'Items'">
      <input type="text" [(ngModel)]="newEntry.ItemNo" placeholder="ItemNo" name="itemno" required>
      <input type="text" [(ngModel)]="newEntry.Title" placeholder="Title" name="title" required>
      <input type="text" [(ngModel)]="newEntry.Description" placeholder="Description" name="description" required>
      <input type="text" [(ngModel)]="newEntry.Collection" placeholder="Collection" name="collection" required>
      <input type="text" [(ngModel)]="newEntry.Creatorid" placeholder="Creatorid" name="creatorid" required>
      <input type="text" [(ngModel)]="newEntry.Dated" placeholder="Dated" name="dated" required>
      <input type="text" [(ngModel)]="newEntry.Image" placeholder="Image" name="image" required>
      <input type="text" [(ngModel)]="newEntry.Place" placeholder="Place" name="place" required>
      <input type="text" [(ngModel)]="newEntry.Origin" placeholder="Origin" name="origin" required>
      <input type="text" [(ngModel)]="newEntry.EntryDate" placeholder="EntryDate:YYYY-MM-DD" name="entrydate" required>
      <input type="text" [(ngModel)]="newEntry.OldItemNo" placeholder="OldItemNo" name="olditemno" required>
      <input type="text" [(ngModel)]="newEntry.Exhibitions" placeholder="Exhibitions" name="exhibitions" required>
      <input type="text" [(ngModel)]="newEntry.Bibliography" placeholder="Bibliography" name="bibliography" required>
      <input type="text" [(ngModel)]="newEntry.HandlePermission" placeholder="HandlePermission" name="handlepermission" required>
      <label for="digitized">Digitized  ?</label>
      <input type="checkbox" [(ngModel)]="newEntry.Digitized" id="digitized" name="digitized" required>
      <input type="text" [(ngModel)]="newEntry.Filename" placeholder="Filename" name="filename" required>
      <input type="text" [(ngModel)]="newEntry.Position" placeholder="Position" name="position" required>
      <input type="text" [(ngModel)]="newEntry.Preservation" placeholder="Preservation" name="preservation" required>
      <input type="text" [(ngModel)]="newEntry.Comments" placeholder="Comments" name="comments" required>
      <input type="text" [(ngModel)]="newEntry.Syntelestis" placeholder="Syntelestis" name="syntelestis" required>
      <input type="text" [(ngModel)]="newEntry.Documenter" placeholder="Documenter" name="documenter" required>
    </ng-container>

    <ng-container *ngIf="dataType === 'Photographers'">
      <input type="text" [(ngModel)]="newEntry.name" placeholder="Name" name="name" required>
      <input type="text" [(ngModel)]="newEntry.surname" placeholder="Surname" name="surname" required>
      <input type="text" [(ngModel)]="newEntry.cv" placeholder="CV" name="cv" required>
      <input type="text" [(ngModel)]="newEntry.birthday" placeholder="Birthday:YYYY-MM-DD" name="birthday" required>
      <input type="text" [(ngModel)]="newEntry.deathday" placeholder="Deathday:YYYY-MM-DD" name="deathday" required>
    </ng-container>

    <button type="submit" style="border: 2px solid;" onmouseover="this.style.color='green'; this.style.borderColor='green'; this.style.fontWeight='bold'" onmouseout="this.style.color=''; this.style.borderColor='black'; this.style.fontWeight='normal'">Υποβολή</button>
  </form>
</div> -->

<app-data-grid-edit-form *ngIf="editData" [data]="editData" [dataType]="dataType" (save)="onSaveEdit($event)" (cancel)="onCancelEdit()"></app-data-grid-edit-form>
<app-data-grid-edit-form *ngIf="showNewEntryForm" [data]="newEntry" [dataType]="dataType" (save)="onSaveNewEntry($event)" (cancel)="onCancelNewEntry()"></app-data-grid-edit-form>

<!-- Delete Confirmation Modal -->
<div *ngIf="showDeleteConfirmation" class="modal">
  <div class="modal-content">
    <p>Are you sure you want to delete this item?</p>
    <button class="delete-buttons" (click)="confirmDelete()">OK</button>
    <button class="delete-buttons" (click)="cancelDelete()">Cancel</button>
  </div>
</div>
