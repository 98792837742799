import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-data-grid-edit-form',
  templateUrl: './data-grid-edit-form.component.html',
  styleUrls: ['./data-grid-edit-form.component.css']
})
export class DataGridEditFormComponent {
  @Input() data: any;
  @Input() dataType: 'Items' | 'Photographers';
  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();

  onSave() {
    this.save.emit(this.data);
  }

  onCancel() {
    this.cancel.emit();
  }
}
