<div class='container'>
    <div class="row">
        <div class="col">
            <h3 class="edit-exhibition">{{'Edit digital exhibition' | translate}}</h3>
        </div>
    </div>
    
    <div class="row">
        <div class="col">
            <p style="margin-bottom: 5px; padding-top: 10px; font-size: 20px;">{{firstname}} {{lastname}}</p>
        </div>
    </div>
    
    <form #userForm="ngForm" (NgSubmit)="userForm.form.valid">
    
        <div class="row">
            <div class="col">
                <h6 class="tag_instructions">{{'Title:' | translate}}</h6>
                <input type="text" class="input_artwork_title form-control" id="title" [maxlength]="100" name="title" [(ngModel)]="exhibition.title" #title="ngModel"
                placeholder="{{'Add a title' | translate}}" required><br>
                <p class="characters_remaining">{{100 - exhibition.title.length }} {{'/100 Characters remaining' | translate}}</p>
            </div>
        </div>

        <div class="row" style="margin-top: 15px; text-align: center; margin-bottom: 25px; padding-bottom: 20px;"> 
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button (click)="onEditExhibitionSubmitApprove(userForm)" class="approve_artwork">{{'Approve Digital Exhibition' | translate}}</button>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button (click)="onEditExhibitionSubmitReject(userForm)" class="reject_artwork">{{'Reject Digital Exhibition' | translate}}</button>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button [routerLink]="['/profile-curator']" class="cancel_artwork">{{'Cancel' | translate}}</button>
            </div>
        </div>
        
    </form>
</div>
