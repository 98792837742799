import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { environment } from 'src/environments/environment';
import { ICellRendererAngularComp } from 'ag-grid-angular';

// interface IItemRow {
//   id: number;
//   ItemNo: string;
//   Title: string;
//   Description: string;
//   Collection: string;
//   Creatorid: string;
//   Dated: string;
//   Image: string;
//   Place: string;
//   Origin: string;
//   EntryDate: string;
//   OldItemNo: string;
//   Exhibitions: string;
//   Bibliography: string;
//   HandlePermission: string;
//   Digitized: string;
//   FileName: string;
//   Position: string;
//   Preservation: string;
//   Comments: string;
//   Syntelestis: string;
//   Documenter: string;
// }

// interface IPhotographerRow {
//   id: number;
//   Name: string;
//   Surname: string;
//   CV: string;
//   Birthday: string;
//   Deathday: string;
//   Created: string;
//   Updated: string;
// }

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css'],
})
export class DataGridComponent implements OnInit {
  themeClass = 'ag-theme-alpine';
  rowData: any[] = []; // Update as per actual response structure
  showNewEntryForm = false;
  newEntry: any = {}; // Use 'any' type for newEntry to accommodate both IItemRow and IPhotographerRow
  dataType: 'Items' | 'Photographers' = 'Items'; // Default to Items
  editData: any = null;
  showDeleteConfirmation = false;
  dataToDelete: any = null;

  itemColDefs: ColDef<any>[] = [
    {
      field: 'operations',
      cellRenderer: (params: any) => {
        return `
          <button data-action="edit">Edit</button>
          <button data-action="delete">Delete</button>
        `;
      },
      cellRendererParams: {
        context: {
          componentParent: this
        }
      },
      onCellClicked: (params: any) => {
        const action = params.event.target.getAttribute('data-action');
        if (action === 'edit') {
          this.onEdit(params.data);
        } else if (action === 'delete') {
          this.onDelete(params.data);
        }
      }
    },
    { field: 'id' },
    { field: 'ItemNo', minWidth: 200 },
    { field: 'Title', minWidth: 500 },
    { field: 'Description', minWidth: 500 },
    { field: 'Collection' },
    { field: 'Creatorid' },
    { field: 'Dated' },
    { field: 'Image' },
    { field: 'Place' },
    { field: 'Origin' },
    { field: 'EntryDate' },
    { field: 'OldItemNo' },
    { field: 'Exhibitions' },
    { field: 'Bibliography' },
    { field: 'HandlePermission' },
    { field: 'Digitized' },
    { field: 'Filename' },
    { field: 'Position' },
    { field: 'Preservation' },
    { field: 'Comments' },
    { field: 'Syntelestis' },
    { field: 'Documenter' },
  ];

  photographerColDefs: ColDef<any>[] = [
    {
      field: 'operations',
      cellRenderer: (params: any) => {
        return `
          <button data-action="edit">Edit</button>
          <button data-action="delete">Delete</button>
        `;
      },
      cellRendererParams: {
        context: {
          componentParent: this
        }
      },
      onCellClicked: (params: any) => {
        const action = params.event.target.getAttribute('data-action');
        if (action === 'edit') {
          this.onEdit(params.data);
        } else if (action === 'delete') {
          this.onDelete(params.data);
        }
      }
    },
    { field: 'id' },
    { field: 'name' },
    { field: 'surname' },
    { field: 'cv' },
    { field: 'birthday' },
    { field: 'deathday' },
    { field: 'created' },
    { field: 'updated' },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
    cellStyle: { 'min-width': '200px', 'white-space': 'normal' },
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData(); // Fetch initial data
  }

  fetchData(): void {
    const endpoint = this.dataType === 'Items' ? '/works' : '/artists'; // Adjust endpoint based on dataType
    this.http.get<any[]>(environment.apiUrl + endpoint).subscribe(
      (data) => {
        this.rowData = data; // Assuming data structure matches
        this.sizeToFit(); // Adjust grid columns
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  addNewEntry(): void {
    const endpoint = this.dataType === 'Items' ? '/works' : '/artists'; // Adjust endpoint based on dataType
    this.http.post<any>(environment.apiUrl + endpoint, this.newEntry).subscribe(
      (newData) => {
        this.rowData.push(newData); // Assuming newData is the newly added item
        // this.showNewEntryForm = false;
        this.newEntry = {}; // Clear newEntry after submission
        this.sizeToFit(); // Adjust grid columns
        alert('Created successfully!');
      },
      (error) => {
        console.error('Error adding new entry:', error);
      }
    );
  }

  sizeToFit(): void {
    setTimeout(() => {
      const gridApi = (document.querySelector('.ag-grid-angular') as any)?.__agGridAngular.gridOptions.api;
      gridApi?.sizeColumnsToFit();
    });
  }

  openNewEntryForm(): void {
	if (this.showNewEntryForm == false) {
		this.showNewEntryForm = true;
	}
	else {
		this.showNewEntryForm = false;
	}
  }

//   openNewEntryForm(): void {
//     this.showNewEntryForm = true;
//   }

  switchDataType(type: 'Items' | 'Photographers'): void {
    this.dataType = type;
    this.fetchData();
	console.log(this.dataType);
  }

  get columnDefs(): ColDef<any>[] {
    return this.dataType === 'Items' ? this.itemColDefs : this.photographerColDefs;
  }

  onEdit(data: any) {
    this.editData = { ...data };
    console.log('works', this.editData);
  }

  onDelete(data: any) {
    this.dataToDelete = data;
    this.showDeleteConfirmation = true;
  }

  confirmDelete() {
    this.deleteWork(this.dataToDelete.id).subscribe(
      () => {
        this.rowData = this.rowData.filter(item => item.id !== this.dataToDelete.id);
        this.showDeleteConfirmation = false;
        this.dataToDelete = null;
        alert('Deleted successfully!');
      },
      (error) => {
        console.error('Error deleting entry:', error);
      }
    );
  }

  cancelDelete() {
    this.showDeleteConfirmation = false;
    this.dataToDelete = null;
  }

  onSaveEdit(updatedData: any) {
    this.editWork(this.editData.id, updatedData).subscribe(
      () => {
        const index = this.rowData.findIndex(item => item.id === this.editData.id);
        if (index !== -1) {
          this.rowData[index] = { ...this.editData, ...updatedData };
        }
        this.editData = null;
        alert('Updated successfully!');
        this.fetchData();
      },
      (error) => {
        console.error('Error updating entry:', error);
      }
    );
  }

  deleteWork(id: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (this.dataType === 'Items') {
      return this.http.delete<any>(environment.apiUrl + '/works/' + id, { headers: headers }).pipe();
    }
    else {
      return this.http.delete<any>(environment.apiUrl + '/artists/' + id, { headers: headers }).pipe();
    }
  }

  editWork(id: string, editBody: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (this.dataType === 'Items') {
      return this.http.put<any>(environment.apiUrl + '/works/' + id, editBody, { headers: headers }).pipe();
    }
    else {
      return this.http.put<any>(environment.apiUrl + '/artists/' + id, editBody, { headers: headers }).pipe();
    }
  }

  onCancelEdit() {
    this.editData = null;
  }
  

  onCancelNewEntry(): void {

    this.showNewEntryForm = false;

  }

  onSaveNewEntry(event: any): void {

    this.addNewEntry();
    this.showNewEntryForm = false;
    this.fetchData();
  }

}