<div class="row" style="position: relative;width: 100%;">
    <img *ngIf="Link1" src="assets/images/espa.jpg" class="espa_banner" alt="Espa banner">
    <img *ngIf="Link2" src="assets/images/espa-english.jpg" class="espa_banner" alt="Espa banner english">
</div>

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" style="background-color: #fff !important;">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['#']" (click)="collapseNav()">
            <img alt="Momus logo" src="assets/images/momus_logo.png" width="150" height="32">
        </a>
        <button #navbarToggler class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbarCollapsing()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [class.collapse]="navbarCollapsed" class="collapse navbar-collapse all-item-mdiv" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 all-item-mul ml-auto">
                <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" (click)="collapseNav()">{{'ΗΟΜΕ' | translate}}</a>
                </li>
                <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/worksofart']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" (click)="collapseNav()">{{'ARTWORKS' | translate}}</a>
                </li>
                <!-- <li class="nav-item navbar-center">
                    <a class="nav-link menu" [routerLink]="['/e-exhibitions']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" (click)="collapseNav()">{{'DIGITAL EXHIBITIONS' | translate}}</a>
                </li> -->
                <li class="nav-item navbar-center">
                    <a class="nav-link menu momus_letters" [routerLink]="['/vrpage']" [routerLinkActive]="['active']"
                        [routerLinkActiveOptions]="{exact:true}" (click)="collapseNav()">{{'MOMus VR' | translate}}</a>
                </li>
                <li class="nav-item navbar-center dropdown">
                    <a class="nav-link menu momus_letters" href="#" id="navbarDropdown">
                      PLAY GAMES 🔽
                    </a>
                    <div class="nav-link menu momus_letters dropdown-menu">
                      <a class="nav-link menu momus_letters" href="/minigames/ektos_kadrou/" target="_blank">EKTOS KADROU</a>
                      <a class="nav-link menu momus_letters" href="/minigames/findobject/" target="_blank">FIND OBJECT</a>
                      <a class="nav-link menu momus_letters" href="/minigames/kolaz/" target="_blank">KOLAZ</a>
                      <a class="nav-link menu momus_letters" href="/minigames/multiple_choice/" target="_blank">MULTIPLE CHOICE</a>
                      <a class="nav-link menu momus_letters" href="/minigames/puzzle/" target="_blank">PUZZLE</a>
                    </div>
                  </li>
            </ul>

            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
                <li *ngIf="authService.notLoggedIn()" class="nav-item">
                    <a class="nav-link login" (click)="collapseNav(); openModalLogin()">{{'LOG IN' | translate}}</a>
                </li>
                <!-- <li *ngIf="authService.notLoggedIn()" class="nav-item">
                    <a class="nav-link signup" (click)="collapseNav(); openModalSignup()">{{'SIGN UP' | translate}}</a>
                </li> -->
    
    
                <li *ngIf="!authService.notLoggedIn()" [routerLinkActiveOptions]="{exact:true}"
                    [routerLinkActive]="['active']" class="nav-item">
                    <div>
                        <div class="dropdown">
                            <button class="dropdown-button profile-options" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <span *ngIf="isArtist" class="user-info">{{firstname}} {{lastname}}</span><i
                                    class="fa fa-angle-down user-info-icon"></i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li *ngIf="isArtist"><a class="dropdown-item" [routerLink]="['/profile']" (click)="collapseNav()"><i class="fa fa-plus"></i> {{'Take part' | translate}}</a></li>
                                <li *ngIf="isCurator"><a class="dropdown-item" [routerLink]="['/profile-curator']" (click)="collapseNav()"><i class="fa fa-plus"></i> {{'Curator' | translate}}</a></li>
                                <li><a *ngIf="isArtist" class="dropdown-item" style="cursor: pointer;" (click)="collapseNav(); openModalEditProfile()"><i class="fa fa-user" aria-hidden="true"></i> {{'Edit profile' | translate}}</a></li>
                                <div *ngIf="isArtist">
                                <li><a *ngIf="Link1" class="dropdown-item" value="el" type="button" href="https://forms.gle/vjRybsHHUNwNXkDf7" target="_blank"><i class="fa fa-info-circle" aria-hidden="true"></i>{{'Πειτε μας την γνωμη σας'}}</a></li>
                                <li><a *ngIf="Link2" class="dropdown-item" value="en" type="button" href=" https://forms.gle/eBGC8QPFsRvmQmcw7" target="_blank"><i class="fa fa-info-circle" aria-hidden="true"></i>{{'Tell us your opinion'}}</a></li>
                                </div>
                                <li><a class="dropdown-item" [routerLink]="['/']" (click)="collapseNav(); logoutUser()"><i class="fa fa-sign-out" aria-hidden="true"></i>{{'Log Out' | translate}}</a></li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="nav-item line-item">
                    <img class="vertical-line" src="assets/images/vertical-line.png">
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink1" class="nav-link el active" value="el" (click)="collapseNav(); useLanguage('el')" style="border: none; background: #fff;margin-top: -1px"> 
                    {{'EL' | translate}}</a>
                    <a *ngIf="NavLink2" class="nav-link el" value="el" (click)="collapseNav(); useLanguage('el')" style="border: none; background: #fff;margin-top: -1px">
                        {{'EL' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a *ngIf="NavLink3" class="nav-link en active" value="en" (click)="collapseNav(); useLanguage('en')"  style="border: none; background: #fff;margin-top: -1px">
                    {{'EN' | translate}}
                    </a>
                    <a *ngIf="NavLink4" class="nav-link en" value="en" (click)="collapseNav(); useLanguage('en')"  style="border: none; background: #fff;margin-top: -1px">
                        {{'EN' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <button class="buttonsearch" (click)="collapseNav(); openModalSearch()">
                        <img class="search_icon" src="assets/images/search-icon.jpg">
                    </button>
                </li>
            </ul>
             
        </div>

    </div>
</nav>

<!-- for espa -->
<!-- <div class="row" style="width: 100%;">
    <p style="background-color: aqua">jkashvdkad</p>
    <div class="row" style="position: relative;width: 100%;">
    <img src="assets/images/espa.jpg" alt="Espa banner" width="269" height="54" style="object-fit: contain;position: absolute;top: -45px;right: -15px;">
</div>
</div> -->
<!-- <div class="row" style="position: relative;width: 100%;">
    <img src="assets/images/espa.jpg" alt="Espa banner" width="269" height="54" style="object-fit: contain;position: absolute;top: -45px;right: -15px;">
</div>
 -->

<!-- if we need this: -->
<!-- <div style="width:100%; height:1px;"></div> --><!-- margin-bottom:100px -->

<!-- if we need this: -->
<!-- notifications icon -->
<!-- <li *ngIf="!authService.notLoggedIn()" class="nav-item">
    <span class="material-icons bell-item">notifications</span>
</li> -->

<!-- if we need this: -->
<!-- notifications icon with other way -->
<!-- <span class="material-symbols-outlined search-item">
    search
</span> -->