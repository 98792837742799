import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-worksofart',
  templateUrl: './worksofart.component.html',
  styleUrls: ['./worksofart.component.css']
})
export class WorksofartComponent implements OnInit {
  work: any = {
    owner_id: ''
  };

  orderBody: any = [
    { name: 'Sort: Date (new first)' },
    { name: 'Sort: Date (elder first)' },
    { name: 'Sort: Rating (max first)' },
    { name: 'Sort: Title (alphabetical order)' }
  ];

  artistsBody: any = [];

  selectedOrder: string = 'Sort: Date (new first)';

  selectedArtist: number = 0;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isCheckboxVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  public isUsersOwnProfile_Rating2: boolean = false;

  public workofart_items: any = [];
  public workofart_items_backup: any = [];
  public artists_items: any = [];
  public artists_items_by_id: any = {};

  constructor(
    private dataLayerService: DataLayerService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;
          this.work.owner_id = this.user_id;

          this.isCheckboxVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isWorkStatusVisible = false;
          this.isStatus1Visible = false;
          this.isStatus2Visible = false;
          this.isStatus3Visible = false;

          this.isUsersOwnProfile_Rating2 = false;

          if (user[0]['role'] === 'artist') {
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;
            this.isBookmarkVisible = true;
          }
          if (user[0]['role'] === 'curator') {
            this.isBookmarkVisible = false;
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;
          }

          this.getWorksOfArt();
        }

        else {
          this.isCheckboxVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isBookmarkVisible = false;
          this.isWorkStatusVisible = false;
          this.isStatus1Visible = false;
          this.isStatus2Visible = false;
          this.isStatus3Visible = false;

          this.spinnerService.show();

          // this.userService.getWork()
          //   .pipe()
          //   .subscribe({
          //     next: data => {
          //       this.workofart_items = data["_items"];

          //       // Sort based on artwork created(new first)
          //       this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          //         return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
          //       });

          //       /* console.log("Works of art:", this.workofart_items);*/
          //       // Hide spinner
          //       this.spinnerService.hide();
          //     },
          //     error: error => {
          //       /* console.log("Handle Error: ", error); */
          //       // Handle Error Here
          //     }
          //   });

          this.getWorksOfArt();
        }
      });
  }

  RatingUpdate(e: { rating_update: any }) {
    this.getWorksOfArt();
  }

  BookmarkUpdated(e: { bookmark_updated: any }) {
    this.getWorksOfArt();
  }

  //synarthsh gia handle event modal completed
  ModalCompleted(e: { modal_updated: any }) {
    /* console.log("modal completed event", e); */
    this.getWorksOfArt();
  }

  getWorksOfArt() {
    this.spinnerService.show();

    if (!this.workofart_items) this.workofart_items = [];

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
          this.spinnerService.show();

          this.userService.getWork()
            .pipe()
            .subscribe({
              next: data => {
                this.workofart_items = data;
                console.log("Works of art:", this.workofart_items);

                //this.handleArtistNameFiltering(this.workofart_items);

                // Sort based on artwork created(new first)
                // this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
                //   return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                // });

                // Create workofart items array backup
                this.workofart_items_backup = this.workofart_items;

                /*  console.log("Works of art:", this.workofart_items); */
                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

            this.userService.getArtists()
            .pipe()
            .subscribe({
              next: data => {
                this.artists_items = data;
                for(const artist of data){
                  this.artists_items_by_id[artist.id] = artist;
                  console.log(artist.id);
                }

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
      
      });
  }

  /*
  * Catch event for changing order filter
  */
  changeOrderFilter(e) {
    this.selectedOrder = e.target.value;
    this.filterArtists();
  }

  /*
  * Catch event when changing artist filter
  */
  changeArtistList(e) {
    this.selectedArtist = parseInt(e.target.value);
    this.filterArtists();
  }

  /*
  * Filter artists based on selected filters
  */
  filterArtists() {
    this.workofart_items = []
    
    // Filter based on artist
    if (this.selectedArtist !== 0) {
      /* console.log(this.selectedArtist); */
      this.workofart_items = this.workofart_items_backup.filter((obj) => obj.Creatorid == this.selectedArtist);
    }
    else {
      this.workofart_items = this.workofart_items_backup;

      // Order array
      if (this.selectedOrder === 'Sort: Date (new first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
        });
      }
      else if (this.selectedOrder === 'Sort: Date (elder first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return new Date(obj1._created).getTime() - new Date(obj2._created).getTime();
        });

      }
      else if (this.selectedOrder === 'Sort: Rating (max first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return obj2.avg_rating - obj1.avg_rating;
        });
      }
      else {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return obj1.title.localeCompare(obj2.title);
        });
      }
    }

    // Order array
    if (this.selectedOrder === 'Sort: Date (new first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
      });
    }
    else if (this.selectedOrder === 'Sort: Date (elder first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return new Date(obj1._created).getTime() - new Date(obj2._created).getTime();
      });

    }
    else if (this.selectedOrder === 'Sort: Rating (max first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return obj2.avg_rating - obj1.avg_rating;
      });
    }
    else {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return obj1.title.localeCompare(obj2.title);
      });
    }
  }
}
