<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<div class="container">
    <!-- <div class="row">
        <div class="col">
            <button class="add_an_artwork"><i class="fa fa-plus"></i>{{'Add an artwork' | translate}}</button>
            <button *ngIf="isForAdd" class="create_e_exhibition" [routerLink]="['/set-exhibition']"><i class="fa fa-plus"></i>  {{'Create digital exhibition' | translate}}</button>
        </div>

    </div> -->

    <!-- <form [formGroup]="addanartworkForm">
        <div class="row">
            <div class="col">
                <p class="add-artist-name">{{first_name}} {{last_name}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h3 class="add-title">{{'Upload your artwork' | translate}}</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-6">
                <img [src]="imgURL" *ngIf="imgURL" class="image-upload-artwork">
                <div class="row image-buttons">
                    <input #file type="file" accept='image/*' (change)="fileChangeEvent($event)" id="actual-btn" hidden/>
                    <label for="actual-btn" class="add_an_img"><i class="fa fa-image"></i>{{'Add image' | translate}}</label>
                    <button (click)="deleteImage(imgURL)" class="remove_img delete-entry"><i class="fa fa-trash"></i>{{'Remove image' | translate}}</button>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-6">
                <input type="text" class="input_artwork_title form-control" [maxlength]="80" id="title" name="title"
                    formControlName="title" placeholder="{{'Artwork title (80 characters maximum) *' | translate}}" required><br>
                <h6 class="dimensions">{{'Dimensions (px), filled in automatically when upload image:' | translate}}</h6>
                <div class="mb-3" style="margin-bottom: 0px !important;">
                    <select name="width" class="selectwidth" [disabled]="disableSelect" required style="color: black;opacity: inherit;appearance: none;">
                        <option value="0">{{img_width | translate}}</option>
                    </select>
                    <select name="height" class="selectheight" [disabled]="disableSelect" required style="color: black;opacity: inherit;appearance: none;">
                        <option value="0">{{img_height | translate}}</option>
                    </select>
                </div>
                <h6 class="upload_date">{{'Upload date(day, month, year):' | translate}}</h6>
                <div class="mb-3">
                    <select name="upload_day" class="upload_selectday" [disabled]="disableSelectDate" required style="color: black;opacity: inherit;appearance: none;">
                        <option *ngFor="let day of dayRange" value={{day}}>
                            {{day}}
                        </option>
                    </select>
                    <select name="upload_month" class="upload_selectmonth" [disabled]="disableSelectDate" required style="color: black;opacity: inherit;appearance: none;">
                        <option *ngFor="let month of monthRange" value={{month}}>
                            {{month}}
                        </option>
                    </select>
                    <select name="upload_year" class="upload_selectyear" [disabled]="disableSelectDate" required style="color: black;opacity: inherit;appearance: none;">
                        <option *ngFor="let year of yearRange" value={{year}}>
                            {{year}}
                        </option>
                    </select>
                </div>
                <h6 class="tag_instructions">{{'Tags (Please separate each tag with one space not with comma):' | translate}}</h6>
                <input type="text" [maxlength]="80" class="input_artwork_tags" id="artwork_tags" name="artwork_tags"
                    formControlName="tags" placeholder="{{'(5 tags maximum) *' | translate}}">
                <textarea type="textarea" cols="40" rows="5" class="input_artwork_description form-control" [maxlength]="600" formControlName="description"
                    id="artwork_description" name="artwork_description" placeholder="{{'Artwork description (600 characters maximum) *' | translate}}" required>
                </textarea>
                <br>
                <input type="text" [maxlength]="50" class="itemno" id="itemno" name="itemno"
                    formControlName="itemno" placeholder="ItemNo">
                <br>
                <input type="text" [maxlength]="50" class="input_collection" id="collection" name="collection"
                    formControlName="collection" placeholder="Collection">
                <br>
                <input type="number" class="input_creatorid" id="creatorid" name="creatorid"
                    formControlName="creatorid" placeholder="Creator ID" min="0" max="99999999">
                <br>
                <input type="text" [maxlength]="20" class="input_itemkind" id="itemkind" name="itemkind"
                    formControlName="itemkind" placeholder="Item Kind">
                <br>
                <input type="text" [maxlength]="50" class="input_title" id="title" name="title"
                    formControlName="title" placeholder="Title">
                <br>
                <input type="text" [maxlength]="50" class="input_series" id="series" name="series"
                    formControlName="series" placeholder="Series">
                <br>
                <input type="text" [maxlength]="50" class="input_theme" id="theme" name="theme"
                    formControlName="theme" placeholder="Theme">
                <br>
                <input type="text" [maxlength]="20" class="input_dated" id="dated" name="dated"
                    formControlName="dated" placeholder="Dated">
                <br>
                <input type="text" [maxlength]="50" class="input_place" id="place" name="place"
                    formControlName="place" placeholder="Place">
                <br>
                <input type="text" [maxlength]="100" class="input_form" id="form" name="form"
                    formControlName="form" placeholder="Form">
                <p class="paragraph-confirm">{{'* Make sure it is the final version of the artwork before saving because it cannot be changed.' | translate}}</p>
                <div class="row end-buttons">
                    <button (click)="openModalConfirm()" class="save_artwork">{{'Save' | translate}}</button>
                    <button (click)="clearInputMethod2()" class="cancel_artwork" [routerLink]="['/profile']">{{'Cancel' | translate}}</button>
                </div>
            </div>
        </div>
        
    </form> -->
    <app-data-grid></app-data-grid>
</div>