<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<div class="col" style="padding-bottom: 50px; padding-right: 0px; padding-left: 0px;">
    <div class="card h-100" style="width: 210px; height: 150px; border: none;">
        <!-- each IMAGE of each work-->
        <img *ngIf="!isPreview1Visible" src={{imageUrl}} (click)="openModalPreview()" class="card-img-top" alt="..."
            style="border-radius: 10px; height: 150px; width: 210px; cursor: pointer; box-shadow: 10px 0px 40px rgb(0 0 0 / 16%); object-fit: contain;"/>
        <img *ngIf="!isPreview3Visible" src={{imageUrl}} (click)="openModalPreview3()" class="card-img-top" alt="..."
            style="border-radius: 10px; height: 150px; width: 210px; cursor: pointer; box-shadow: 10px 0px 40px rgb(0 0 0 / 16%); object-fit: contain;"/>
        <img *ngIf="isPreview4Visible" src={{imageUrl}} (click)="openModalPreview4()" class="card-img-top" alt="..."
            style="border-radius: 10px; height: 150px; width: 210px; cursor: pointer; box-shadow: 10px 0px 40px rgb(0 0 0 / 16%); object-fit: contain;"/>
         <!-- Yπόλοιπα στοιχεία του κάθε έργου -->    
        <div class="card-body" style="padding: 10px 0px 0px 0px;">
            <p class="card-title" style="color: #000; font-size: 16px; font-weight: 500;">{{workofart_item.Title}}</p>
                
            <div class="row" style="margin-top: -5px;">
                <div class="col-8">
                    <li class="profile-item" style="display: block">
                        <a class="card-subtitle mb-2" (click)="openUserProfile()" 
                        style="color: #3e3f3a; cursor: pointer; font-weight: 500; font-size: 14px;">{{artist?.name}} {{artist?.surname}}</a>
                    </li>
                </div>
                <div class="col-4" style="text-align: right; margin-left: -3px;">
                    <div style="margin-top: -27px;" *ngIf="isCheckboxVisible">
                        <form [formGroup]="checkboxForm">
                            <input class="class_checkbox" type="checkbox" formControlName="work_selected"
                                [id]="workofart_item._id"
                                style="width: 17px; height: 20px; color: #8e8c84; font-size: 15px; font-weight: 500; margin-top: -55px;">
                        </form>
                    </div>
                </div>
                <div class="col-4" style="text-align: right; margin-left: -3px; ">
                    <div class="dropdown" style="margin-top: -55px;" *ngIf="isSettings1Visible">
                            <button class="class_settings" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 17px; height: 20px; color: #8e8c84; margin-left: 170px; font-weight: 500; border: none; background: none; cursor: pointer;">
                            <span class="material-symbols-outlined" style="font-size: 22px; margin-left: 9px; margin-top: 0px;">
                                settings
                            </span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" style="cursor: pointer;" (click)="openModalConfirmDelete()">{{'Delete Artwork' | translate}}</a></li>
                            </ul>    
                    </div>

                    <div class="dropdown" style="margin-top: -55px;" *ngIf="isSettings2Visible">
                        <button class="class_settings" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                        style="width: 17px; height: 20px; color: #8e8c84; margin-left: 170px; font-weight: 500; border: none; background: none; cursor: pointer;">
                        <span class="material-symbols-outlined" style="font-size: 22px; margin-left: 9px; margin-top: 0px;">
                            settings
                        </span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" style="cursor: pointer;" (click)="openModalConfirmDelete()">{{'Delete Artwork' | translate}}</a></li>
                            <li><a *ngIf="isapproved" class="dropdown-item" (click)="previewWorkEdit()" style="cursor: pointer;">{{'Edit Artwork' | translate}}</a></li>
                            <li><a *ngIf="isapproved" class="dropdown-item" (click)="rejectWork(workofart_item._id)" style="cursor: pointer;">{{'REJECT ARTWORK' | translate}}</a></li>
                            <li><a *ngIf="isrejected" class="dropdown-item" (click)="approveWork(workofart_item._id)" style="cursor: pointer;">{{'APPROVE ARTWORK' | translate}}</a></li>
                            <li><a *ngIf="isaccountdelete" class="dropdown-item" style="cursor: pointer;" (click)="openModalConfirmDeleteAccount()">{{'ACCOUNT DELETION' | translate}}</a></li>
                        </ul>    
                </div>
                </div>
            </div>

            <p class="card-subtitle mb-2" style="color: #3e3f3a; margin-left: 0px; margin-top: 0px;">{{ workofart_item.Dated }}</p>
            <!-- <p class="card-subtitle mb-2" style="color: #3e3f3a; margin-left: 0px; margin-top: 0px;">{{ workofart_item.id }}</p> -->
            <p *ngIf="isTagsVisible" class="card-text" style="color: #8e8c84; font-size: 12px;">{{workofart_item.tags}}</p>
            <p *ngIf="isTagsVisible" (click)="openModalPreview2()" class="card-text" style="color: black; font-size: 12px;"><button>Λεπτομέρειες</button></p>
            

            <div class="row">
             
                <div class="col-4">
                    <div *ngIf="isWorkStatusVisible">

                        <div class="col" style="padding: 0px; text-align: right;">
                            <div *ngIf="isStatus1Visible">
                                <button class="status-pending" data-toggle="tooltip" data-placement="bottom" title="{{'Pending inspection by the curator' | translate}}" [id]="workofart_item._id" type="button" style="border: none; color: orange; background: #fff;">
                                    <span class="material-symbols-outlined" style="font-size: 22px;cursor: auto;background: #fff;">
                                        timelapse
                                    </span>
                                </button>
                            </div>
                        </div>
        
                        <div class="col" style="padding: 0px; text-align: right;">
                            <div *ngIf="isStatus2Visible">
                                <button class="status-approved" data-toggle="tooltip" data-placement="bottom" title="{{'Approved by the curator' | translate}}" [id]="workofart_item._id" type="button" style="border: none; color: #00FF00; background: #fff;" disabled>
                                    <span class="material-symbols-outlined" style="font-size: 22px;cursor: auto;background: #fff;">
                                        check_circle
                                    </span>
                                </button>
                            </div>
                        </div>
        
                        <div class="col" style="padding: 0px; text-align: right;">
                            <div *ngIf="isStatus3Visible">
                                <button class="status-rejected" data-toggle="tooltip" data-placement="bottom" title="{{'Rejected by the curator' | translate}}" [id]="workofart_item._id" type="button" style="border: none; color: red; background: #fff;" disabled>
                                    <span class="material-symbols-outlined" style="font-size: 22px;cursor: auto;background: #fff;">
                                        hide_source
                                    </span>
                                </button>
                            </div>
                        </div>
        
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>