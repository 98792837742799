<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<!-- <link rel="stylesheet"
    href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" /> -->

<div class='container'>
    <div class="row">
        <div class="col">
            <p class="discover">{{'Discover...' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="works_after_discover">{{'Artworks' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="worksofart-text">{{'Enter a world of artworks and be inspired! Enjoy creations by artists and art lovers, share your favorites on social media and if you feel inspired, join the platform and share your own projects with the public.' | translate}}<p>
        </div>
    </div>

    <div class="row filter_row">
        <div class="col">
            <div class="mb-3">
                <!-- <select (change)="changeOrderFilter($event)" class="selectsortdate">
                    <option [value]="n.name" *ngFor="let n of orderBody">{{n.name | translate}}</option>
                </select> -->
                <select (change)="changeArtistList($event)" class="selecdiscoverartists">
                    <option class="" selected value="0">{{'Discover artists' | translate}}</option>
                    <option *ngFor="let n of artists_items" [value]="n.id">
                        {{n.name + ' ' + n.surname}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <br>
    <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
        <div *ngFor="let item of workofart_items">
            <app-worksofart-card [workofart_item]="item"
                [workofart_items]="workofart_items"
                [artist]="artists_items_by_id[item.Creatorid]"
                [isBookmarkVisible]="isBookmarkVisible"
                [isRatethisVisible]="isRatethisVisible" 
                [isRatebuttonVisible]="isRatebuttonVisible" 
                [isVerticallineVisible]="isVerticallineVisible"
                [isCheckboxVisible]="isCheckboxVisible"
                [isSettings1Visible]="isSettings1Visible"
                [isSettings2Visible]="isSettings2Visible"
                [isWorkStatusVisible]="isWorkStatusVisible"
                [isStatus1Visible]="isStatus1Visible"
                [isStatus2Visible]="isStatus2Visible"
                [isStatus3Visible]="isStatus3Visible"
                [isBookMarkChecked]="item.bookmark_selected"
                [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
                (RatingUpdate)= RatingUpdate($event)
                (ModalUpdated)= ModalCompleted($event)
                (BookmarkUpdated)= BookmarkUpdated($event)
                ></app-worksofart-card>
        </div>
    </div>
    <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <p class="card-text" style="color: black; font-size: 12px;"><button>..............</button></p>
    </div>    
</div>