<div class="modal">
	<div class="modal-content">
	  <span class="close" (click)="onCancel()">&times;</span>
	  <form (ngSubmit)="onSave()">
		<div *ngIf="dataType === 'Items'">
		  <label for="ItemNo">Item No:</label>
		  <input type="text" [(ngModel)]="data.ItemNo" name="ItemNo" required>
		  <label for="Title">Title:</label>
		  <input type="text" [(ngModel)]="data.Title" name="Title" required>
		  <label for="Description">Description:</label>
		  <input type="text" [(ngModel)]="data.Description" name="Description" required>
		  <label for="Collection">Collection:</label>
		  <input type="text" [(ngModel)]="data.Collection" name="Collection" required>
		  <label for="Creatorid">Creatorid:</label>
		  <input type="text" [(ngModel)]="data.Creatorid" name="Creatorid" required>
		  <label for="Dated">Dated:</label>
		  <input type="text" [(ngModel)]="data.Dated" name="Dated" required>
		  <label for="Image">Image:</label>
		  <input type="text" [(ngModel)]="data.Image" name="Image" required>
		  <label for="Place">Place:</label>
		  <input type="text" [(ngModel)]="data.Place" name="Place" required>
		  <label for="Origin">Origin:</label>
		  <input type="text" [(ngModel)]="data.Origin" name="Origin" required>
		  <label for="EntryDate">EntryDate:</label>
		  <input type="text" [(ngModel)]="data.EntryDate" name="EntryDate" required>
		  <label for="OldItemNo">OldItemNo:</label>
		  <input type="text" [(ngModel)]="data.OldItemNo" name="OldItemNo" required>
		  <label for="Exhibitions">Exhibitions:</label>
		  <input type="text" [(ngModel)]="data.Exhibitions" name="Exhibitions" required>
		  <label for="Bibliography">Bibliography:</label>
		  <input type="text" [(ngModel)]="data.Bibliography" name="Bibliography" required>
		  <label for="HandlePermission">HandlePermission:</label>
		  <input type="text" [(ngModel)]="data.HandlePermission" name="HandlePermission" required>
		  <label for="Digitized">Digitized:</label>
		  <input type="checkbox" [(ngModel)]="data.Digitized" name="Digitized" required>
		  <label for="Filename">Filename:</label>
		  <input type="text" [(ngModel)]="data.Filename" name="Filename" required>
		  <label for="Position">Position:</label>
		  <input type="text" [(ngModel)]="data.Position" name="Position" required>
		  <label for="Preservation">Preservation:</label>
		  <input type="text" [(ngModel)]="data.Preservation" name="Preservation" required>
		  <label for="Comments">Comments:</label>
		  <input type="text" [(ngModel)]="data.Comments" name="Comments" required>
		  <label for="Syntelestis">Syntelestis:</label>
		  <input type="text" [(ngModel)]="data.Syntelestis" name="Syntelestis" required>
		  <label for="Documenter">Documenter:</label>
		  <input type="text" [(ngModel)]="data.Documenter" name="Documenter" required>
		</div>
		<div *ngIf="dataType === 'Photographers'">
		  <label for="Name">Name:</label>
		  <input type="text" [(ngModel)]="data.name" name="Name" required>
		  <label for="Surname">Surname:</label>
		  <input type="text" [(ngModel)]="data.surname" name="Surname" required>
		  <label for="CV">CV:</label>
		  <input type="text" [(ngModel)]="data.cv" name="CV" required>
		  <label for="Birthday">Birthday:</label>
		  <input type="text" [(ngModel)]="data.birthday" name="Birthday" required>
		  <label for="Deathday">Deathday:</label>
		  <input type="text" [(ngModel)]="data.deathday" name="Deathday" required>
		</div>
		<button type="submit">Save</button>
	  </form>
	</div>
</div>