<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class='container'>
    <div class="row">
        <div class="col">
            <button class="add_an_artwork" [routerLink]="['/addanartwork']"><i class="fa fa-plus"></i> {{'Add an artwork' | translate}}</button>
            <button class="create_e_exhibition"><i class="fa fa-plus"></i> {{'Create digital exhibition' | translate}}</button>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="name">{{firstname}} {{lastname}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h3 class="header" *ngIf="isForAdd">{{'Create your digital exhibition' | translate}}</h3>
            <h3 class="header" *ngIf="isForEdit">{{'Edit your digital exhibition' | translate }}</h3>
        </div>
    </div>

    <div class="row">
        <p class="exhi-title">{{'Title:' | translate}} {{exhibition_obj.title}}</p>
    </div>

    <div class="row">
        <p class="create_exhibition_steps"><span style="font-weight: bold;">{{'Step 3' | translate}}</span> {{'of 6: Select artworks from your favourites:' | translate}}</p>
    </div>
    
    <div class="row">
    <p *ngIf="isParagraphForFavourites" class="paragraph">{{'You have no favourites to choose from' | translate}}</p>
    </div>

    <form [formGroup]="exhibitionStepThreeForm">
        <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
            <div *ngFor="let item of favourite_workofart">
                <app-worksofart-card [workofart_item]="item"
                    [workofart_items]="favourite_workofart"
                    [isCheckboxSelected]="item.checkbox_selected"
                    (SelectedWorkid)=onCheckboxSelected($event)
                    [isRatethisVisible]="isRatethisVisible"
                    [isRatebuttonVisible]="isRatebuttonVisible"
                    [isVerticallineVisible]="isVerticallineVisible"
                    [isSettings1Visible]="isSettings1Visible"
                    [isSettings2Visible]="isSettings2Visible"
                    [isBookMarkChecked]="item.bookmark_selected"
                    [isUsersOwnProfile_Rating]="isUsersOwnProfile_Rating"
                    [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
                    [isPreview1Visible]="isPreview1Visible"
                    [isPreview3Visible]="isPreview3Visible"
                    ></app-worksofart-card>
            </div>
        </div>

        <div class="row row_back_next">
            <div class="col col_navigate">
                <button (click)="GoBackToStepTwo()" class="exhibition_btn_back" type="button">{{'Back' | translate}}</button>
                <button (click)="StepthreeSubmit()" class="exhibition_btn_next" type="button">{{'Next' | translate}}</button>
            </div>
        </div>

        <div class="row row_cancel">
            <div class="ml-auto">
                <a [routerLink]="['/profile']" class="exhibition_cancel" type="button">{{'Cancel' | translate}}</a>
            </div>
        </div>
    </form>

</div>