import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken: string | null = null;
  private refreshToken: string | null = null;

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();
  environment = environment;

  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.environment.apiUrl}/auth/login`, { email, password }).pipe(
      tap(response => {
        console.log('Login Response:', response);
  
        this.accessToken = response.access_token;
        this.refreshToken = response.refresh_token;
  
        // Check if the refresh token is present
        if (this.refreshToken) {
          localStorage.setItem('access_token', this.accessToken);
          localStorage.setItem('refresh_token', this.refreshToken);
          console.log('Access Token:', this.accessToken);
          console.log('Refresh Token:', this.refreshToken);
        } else {
          console.error('No refresh token received');
        }
  
        this.isLoggedInSubject.next(true);
      }),
      catchError(err => {
        this.isLoggedInSubject.next(false);
        throw err;
      })
    );
  }

  logout(): void {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.isLoggedInSubject.next(false);
  }

  refresh(): Observable<any> {
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
      console.error('No refresh token found');
      return throwError('No refresh token found');
    }

    return this.http.post<any>(`${this.environment.apiUrl}/auth/refresh`, {}, {
      headers: new HttpHeaders({ Authorization: `Bearer ${refreshToken}` })
    }).pipe(
      tap(response => {
        this.accessToken = response.access_token;
        localStorage.setItem('access_token', this.accessToken);
      }),
      catchError(err => {
        console.error('Refresh failed', err);
        throw err;
      })
    );
  }

  getAccessToken(): string | null {
    return this.accessToken || localStorage.getItem('access_token');
  }

  isAuthenticated(): boolean {
    return this.isLoggedInSubject.value;
  }
}
