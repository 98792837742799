import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile-curator',
  templateUrl: './profile-curator.component.html',
  styleUrls: ['./profile-curator.component.css']
})
export class ProfileCuratorComponent implements OnInit {
  work: any = {
    owner_id: '',
    _id: ''
  };

  public workofart_items: any = [];
  public workofart_items_all: any = [];
  public exhibition_items: any = [];
  public exhibition_items_all: any = [];

  statusBody: any = [{ name: 'Pending' }, { name: 'Approved' }, { name: 'Rejected' }];

  selected: string = 'Pending';

  workBody: any = [{ name: 'Sort: Date (new first)' }, { name: 'Sort: Date (elder first)' }, { name: 'Sort: Rating (max first)' }, { name: 'Sort: Title (alphabetical order)' }];

  exhibitionBody: any = [{ name: 'Sort: Date (new first)' }, { name: 'Sort: Date (elder first)' }, { name: 'Sort: Title (alphabetical order)' }];

  selectedForWorksAndExhibitions: string = 'Sort: Date (new first)';

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isAvgRatingVisible: boolean = true;
  public isStarRatingVisible: boolean = true;

  public isCheckboxVisible: boolean = true;
  public isBookMarkChecked: boolean = true;

  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;

  public isSettings2VisibleExhib: boolean = true;

  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  public isStatusExhibition1Visible: boolean = false;
  public isStatusExhibition2Visible: boolean = false;
  public isStatusExhibition3Visible: boolean = false;

  public isPreview1Visible: boolean = false;
  public isPreview4Visible: boolean = true;

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.user_id = user[0]._id;
          this.work.owner_id = this.user_id;

          this.isCheckboxVisible = false;
          this.isRatethisVisible = false;
          this.isRatebuttonVisible = false;
          this.isVerticallineVisible = false;
          this.isAvgRatingVisible = false;
          this.isStarRatingVisible = false;
          this.isBookMarkChecked = false;
          this.isBookmarkVisible = false;

          this.isSettings1Visible = false;
          this.isSettings2Visible = true;

          this.isSettings2VisibleExhib = true;

          this.isWorkStatusVisible = true;
          this.isStatus1Visible = false;
          this.isStatus2Visible = false;
          this.isStatus3Visible = false;

          this.isStatusExhibition1Visible = false;
          this.isStatusExhibition2Visible = false;
          this.isStatusExhibition3Visible = false;

          this.isPreview1Visible = false;
          this.isPreview4Visible = true;

          this.spinnerService.show();

          this.userService.getWorkOfAllArtists()
            .pipe()
            .subscribe({
              next: data => {
                this.isWorkStatusVisible = true;
                this.isPreview4Visible = false;
                this.workofart_items_all = data["_items"];
                /*  console.log('All Works of art:', this.workofart_items_all); */

                // Sort based on artwork created(new first)
                this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                // Filter based on artwork status
                this.workofart_items = this.workofart_items_all.filter((obj) => {
                  return obj.status === "PENDING";
                });

                /* console.log('Works of art:', this.workofart_items); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });


          this.spinnerService.show();

          this.userService.getExhibitionOfallfArtistsCurator()
            .pipe()
            .subscribe({
              next: data => {
                this.isSettings1Visible = false;
                this.exhibition_items_all = data["_items"];
                /* console.log("All exhibition Items: ", this.exhibition_items_all); */

                // Sort based on exhibition created(new first)
                this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                // Filter based on exhibition status
                this.exhibition_items = this.exhibition_items_all.filter((obj) => {
                  return obj.status === "PENDING";
                });
                this.isStatusExhibition1Visible = true;

                /* console.log('e-Exhibitions:', this.exhibition_items); */

                // Hide spinner
                this.spinnerService.hide();

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }
      });
  }

  RatingUpdate(e: { rating_update: any }) {
    this.spinnerService.show();

    this.userService.getWorkOfAllArtists()
      .pipe()
      .subscribe({
        next: data => {
          this.isWorkStatusVisible = true;
          this.isPreview4Visible = false;
          this.workofart_items_all = data["_items"];
          /* console.log('All Works of art:', this.workofart_items_all); */

          // Sort based on artwork created(new first)
          this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
            return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
          });

          // Filter based on artwork status
          this.workofart_items = this.workofart_items_all.filter((obj) => {
            return obj.status === "PENDING";
          });

          /*  console.log('Works of art:', this.workofart_items); */

          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }

  UpdateStatusWork(e: { status_update: any }) {
    this.workofart_items = this.workofart_items.filter(
      (obj: any) => obj.status === e.status_update
    );

    this.spinnerService.show();

    this.userService.getWorkOfAllArtists()
      .pipe()
      .subscribe({
        next: data => {
          this.workofart_items_all = data["_items"];
          /* console.log('All Works of art:', this.workofart_items_all); */

          // Filter based on artwork status
          this.workofart_items = this.workofart_items_all.filter((obj) => {
            return obj.status === "PENDING";
          });

          /* console.log('Pending works of art:', this.workofart_items); */

          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }

  UpdateStatusExhibition(e: { status_update: string }) {
    this.exhibition_items = this.exhibition_items.filter(
      (obj: any) => obj.status === e.status_update
    );

    this.spinnerService.show();

    this.userService.getExhibitionOfallfArtistsCurator()
      .pipe()
      .subscribe({
        next: data => {
          this.isSettings1Visible = false;
          this.exhibition_items_all = data["_items"];
          /* console.log("All exhibition Items: ", this.exhibition_items_all); */

          // Filter based on exhibition status
          this.exhibition_items = this.exhibition_items_all.filter((obj) => {
            return obj.status === "PENDING";
          });

          /* console.log('Pending exhibitions:', this.exhibition_items); */

          // Hide spinner
          this.spinnerService.hide();

        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }

  changeWorkStatus(e) {
    /*  console.log(e.target.value); */
    this.selected = e.target.value;
    /* console.log(this.selected); */

    this.isSettings2Visible = true;

    if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all);*/

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /*  console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /*  console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else {
      this.isSettings2Visible = true;
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }

  changeExhibitionStatus(e) {
    this.isStatusExhibition1Visible = false;
    this.isStatusExhibition2Visible = false;
    this.isStatusExhibition3Visible = false;

    this.isSettings2VisibleExhib = true;

    /*  console.log(e.target.value); */
    this.selected = e.target.value;
    /* console.log(this.selected); */

    if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /*  console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /*  console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else {
      this.isSettings2VisibleExhib = true;
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }

  changeWorkFilter(e) {
    /* console.log(e.target.value); */
    this.selectedForWorksAndExhibitions = e.target.value;
    /* console.log(this.selectedForWorksAndExhibitions); */

    if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /* console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork created(new first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /* console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /*  console.log('All Works of art:', this.workofart_items_all); */

            // Custom sort based on artwork created(elder first)

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Rating (max first)') {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /*  console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork rating(max rating first)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj2.avg_rating - obj1.avg_rating;
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else {
      this.spinnerService.show();

      this.userService.getWorkOfAllArtists()
        .pipe()
        .subscribe({
          next: data => {
            this.isWorkStatusVisible = true;
            this.workofart_items_all = data["_items"];
            /*  console.log('All Works of art:', this.workofart_items_all); */

            // Sort based on artwork alphabetical order(title)
            this.workofart_items = this.workofart_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on artwork status
            this.workofart_items = this.workofart_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });

            /*  console.log('Works of art:', this.workofart_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }

  changeExhibitionFilter(e) {
    this.isStatusExhibition1Visible = false;
    this.isStatusExhibition2Visible = false;
    this.isStatusExhibition3Visible = false;

    /* console.log(e.target.value); */
    this.selectedForWorksAndExhibitions = e.target.value;
    /*  console.log(this.selectedForWorksAndExhibitions); */

    if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /*  console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /*  console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Pending' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "PENDING";
            });
            this.isStatusExhibition1Visible = true;

            /*  console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Approved' && this.selectedForWorksAndExhibitions === 'Sort: Title (alphabetical order)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "APPROVED";
            });
            this.isStatusExhibition2Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /* console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else if (this.selected === 'Rejected' && this.selectedForWorksAndExhibitions === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Custom sort based on exhibition created(new first)

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }

    else {
      this.spinnerService.show();

      this.userService.getExhibitionOfallfArtistsCurator()
        .pipe()
        .subscribe({
          next: data => {
            this.isSettings1Visible = false;
            this.exhibition_items_all = data["_items"];
            /*  console.log("All exhibition Items: ", this.exhibition_items_all); */

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            // Filter based on exhibition status
            this.exhibition_items = this.exhibition_items_all.filter((obj) => {
              return obj.status === "REJECTED";
            });
            this.isStatusExhibition3Visible = true;

            /* console.log('e-Exhibitions:', this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }
}
